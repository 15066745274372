*,
*::before,
*::after {

    box-sizing: border-box;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-Thin.ttf') format('truetype'),  local('Montserrat');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-ExtraLight.ttf') format('truetype'),  local('Montserrat');
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-Light.ttf') format('truetype'),  local('Montserrat');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-Regular.ttf') format('truetype'),  local('Montserrat');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-Medium.ttf') format('truetype'),  local('Montserrat');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-SemiBold.ttf') format('truetype'),  local('Montserrat');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-Bold.ttf') format('truetype'),  local('Montserrat');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-ExtraBold.ttf') format('truetype'),  local('Montserrat');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-Black.ttf') format('truetype'),  local('Montserrat');
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-ThinItalic.ttf') format('truetype'),  local('Montserrat');
    font-style: italic;
    font-weight: 100;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype'),  local('Montserrat');
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-LightItalic.ttf') format('truetype'),  local('Montserrat');
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-Italic.ttf') format('truetype'),  local('Montserrat');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-MediumItalic.ttf') format('truetype'),  local('Montserrat');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype'),  local('Montserrat');
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-BoldItalic.ttf') format('truetype'),  local('Montserrat');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype'),  local('Montserrat');
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: 'Montserrat';
    src: url('style/fonts/Montserrat-BlackItalic.ttf') format('truetype'),  local('Montserrat');
    font-style: italic;
    font-weight: 900;
}

html,
body {
    margin: 0;
    padding: 0;

    font-size: 16px;
    font-family: 'Montserrat', sans-serif;

    background: #29353E;
}

button,
input {
    font-family: 'Montserrat', sans-serif;
}
